import 'malihu-custom-scrollbar-plugin';
import {debounce} from 'lodash';

const options = {
	theme: 'dark',
	scrollEasing: 'easeInOut',
	scrollbarPosition: 'outside',
	scrollInertia: 200,
	mouseWheel: {
		scrollAmount: '50%',
	},
};

const optionsTwo = {
	theme: 'dark',
	scrollEasing: 'easeInOut',
	scrollInertia: 200,
	mouseWheel: {
		scrollAmount: '50%',
	},
};

const scrollRemoveMobile = () => {
	const $boxScrollRed = $('.js-scroll-red');
	const $boxScroll = $('.js-scroll, .case__info');
	const isMobile = window.matchMedia('(min-width: 768px)').matches;

	if (isMobile) {
		$boxScroll.mCustomScrollbar(options);
		$boxScrollRed.mCustomScrollbar(optionsTwo);
	} else {
		$boxScrollRed.mCustomScrollbar('destroy');
		$boxScroll.mCustomScrollbar('destroy');
	}
};

$(window).on('load resize', debounce(scrollRemoveMobile, 200));
