import $ from 'jquery';
import {debounce} from 'lodash';

const widthAtResize = (element, fn = () => {}) => {
	return $(window).on('load resize', debounce(() => {
		const newWidth = $(element).width();

		fn(newWidth);

		return newWidth;
	}, 200));
};

export default widthAtResize;
