import {truncate, isEmpty, random} from 'lodash';

const $forms = $('#form-call, #form-open, #form-contacts, #form-order');
const $inputsFile = $('input[type=file]');

$inputsFile.on('change', (event) => {
	const self = event.currentTarget;
	const {name} = $(self)[0].files[0];
	const $boxName = $(self).next('span');

	$boxName.text(truncate(name));

	if (!isEmpty(name)) {
		$(self).parent('label').addClass('is-active');
	}
});

$forms.on('submit', (event) => {
	event.preventDefault();
	const $form = $(event.currentTarget);
	const $btn = $form.find('.btn');
	const $input = $form.find('input, textarea');
	const $inputFile = $form.find('input[type=file]');
	const titleText = $form.find('.order__name').text();
	const data = new FormData($form[0]);
	const randomIdOrder = `[#${random(10000, 90000)}]`;
	const isOrder = $form.data('order');

	$btn.attr('disabled', true);

	data.append('order-id', randomIdOrder);

	if (!isEmpty(titleText)) {
		data.append('title', titleText);
	}

	if (!isEmpty(isOrder)) {
		data.append('order', 'order');
	}

	const clearForm = () => {
		$input.val('');
		$btn.text('Отправить заявку').attr('disabled', false);
		$inputFile.next('span').text('Прикрепить документ');
		$inputFile.parent('label').removeClass('is-active');
	};

	const successMessageAndClear = (message) => {
		$btn.text(message);
		setTimeout(clearForm, 4000);
	};

	$.ajax({
		url: 'mail.php',
		type: 'POST',
		async: true,
		data,
		datatype: 'json',
		processData: false,
		contentType: false,
		cache: true,
		global: false,
		success: (() => {
			function success(msg) {
				if (msg !== 'ok') {
					successMessageAndClear('Ошибка');
				} else {
					successMessageAndClear('Заявка отправлена');
				}
			}

			return success;
		})(),
	});
});

