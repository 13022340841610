import {debounce} from 'lodash';
import $ from 'jquery';

$(() => {
	let animatedMenu = new TimelineMax({pause: true});

	let animatedDesc = new TimelineMax({pause: true});

	$(document).on('click', '.js-menu__open', function () {
		if ($('.menu__item-5.active').length) {
			animatedMenuContact.reverse();
		}
		let $this = $(this);

		let parents = $this.parent();

		let menu = parents.find('ul li');

		let index = parents.index();

		let offset = [-21.3, -17.5, -13.2, -4.3];

		const $video = parents.find('video');

		if (animatedMenu.isActive()) {
			return false;
		}

		if (parents.hasClass('active')) {
			$video.get(0).pause();

			animatedMenu
				.call(() => {
					animatedDesc.to('.home__desc', 0.5, {opacity: 0})
						.to('.home__desc', 0.5, {opacity: 1});
					parents.toggleClass('active');
					$('.home__logo').attr('class', 'home__logo').css('transform', 'translateX(0vw)');
				})
				.staggerFromTo(menu, 0.1,
					{y: 0,
						opacity: 1,
						ease: Expo.easeInOut},
					{y: -20,
						opacity: 0,
						ease: Expo.easeInOut},
					0.1);
		} else {
			$video.get(0).play();

			closeMainMenu(parents.siblings('.active'));
			animatedMenu
				.call(() => {
					animatedDesc.to('.home__desc', 0.3, {opacity: 0});
					parents.toggleClass('active');
					$('.home__logo').addClass(`on on-${index}`).css('transform', `translateX(calc(50% + ${offset[index]}vw))`);
				})
				.staggerFromTo($(menu.get().reverse()), 0.3,
					{y: -30,
						opacity: 0,
						ease: Expo.easeInOut},
					{y: 0,
						opacity: 1,
						ease: Expo.easeInOut},
					0.1);
		}
	});

	function closeMainMenu(el) {
		let item = el || $('.menu__item.active');

		let itemMenu = item.find('ul li');

		let closeMenu = new TimelineMax({pause: true});

		animatedNav.reverse();

		closeMenu
			.call(() => {
				$('.home__logo').attr('class', 'home__logo').css('transform', 'translateX(0vw)');
				item.removeClass('active');
			})
			.staggerFromTo(itemMenu, 0.2,
				{y: 0,
					opacity: 1,
					ease: Expo.easeInOut},
				{y: -20,
					opacity: 0,
					ease: Expo.easeInOut},
				0.05, '+=0');
	}

	var animatedMenuContact = new TimelineMax({pause: true});

	let contactParents = $('.menu__item-5');

	let contactItems = $('.menu__item-5 .menu__contact').children();

	animatedMenuContact
		.to(contactParents, 0.001, {className: '+=active'})
		.staggerFromTo($(contactItems.get().reverse()), 0.25,
			{y: -30,
				opacity: 0,
				ease: Expo.easeInOut},
			{y: 0,
				opacity: 1,
				ease: Expo.easeInOut},
			0.05)
		.pause();

	animatedMenuContact.eventCallback('onReverseComplete', () => {
		contactParents.removeClass('on');
	});

	$(document).on('click', '.js-menu__contact', () => {
		if (animatedMenuContact.isActive()) {
			return false;
		}
		closeMainMenu();
		if (contactParents.hasClass('active')) {
			animatedMenuContact.reverse();
		} else {
			contactParents.addClass('on');
			animatedMenuContact.play();
		}
	});

	let animatedSocial = [];

	$('.social__link').each(function () {
		let $this = $(this);

		let svg = $this.find('svg');

		let item = new TimelineMax({pause: true})
			.to($this, 0.001, {className: '+=active'})
			.to(svg, 0.15,
				{opacity: 1},
				0.3)
			.to(svg, 0.3,
				{filter: 'drop-shadow(0 0 1.5rem rgba(0, 0, 0, 0.4))',
					x: '25%'},
				0.3)
			.pause();

		animatedSocial.push(item);
	});

	$('.social__link').hover(
		function () {
			let index = $(this).parent().index();

			animatedSocial[index]
				.timeScale(1)
				.play();
		},
		function () {
			let index = $(this).parent().index();

			animatedSocial[index]
				.timeScale(2)
				.reverse();
		}
	);

	$(document).on('click', '.social__link', function (e) {
		e.preventDefault();
		const isMobile = $(window).width() < 767;
		const $loader = $('.social__loader');

		$loader.find('span').remove();

		let $this = $(this);

		let color = $this.data('color');

		let klass = $this.data('class');

		let href = $this.attr('href');

		const addSpanToLoader = (limit = 0, maxSpan = 0) => {
			while (maxSpan < limit) {
				$loader.append('<span><span></span></span>');
				maxSpan++;
			}
		};

		if (isMobile) {
			addSpanToLoader(6);
		} else {
			addSpanToLoader(8);
		}

		$('body').addClass('is-loader');

		$('.social__loader_content').html($this.find('.icon').clone().attr('style', ''));

		$('.social__loader').css('background-color', `#${color}`);
		$('.social__loader').addClass(klass);

		let animatedSocialLink = new TimelineMax({pause: true});

		animatedSocialLink
			.to($('.home'), 0.1, {className: '-=active'})
			.set($('.social__loader'), {visibility: 'visible'})
			.to($('.social__loader'), 0.5, {opacity: 1})

			.staggerFromTo($('.social__loader > span'), 0.35,
				{y: 0},
				{y: '-100%'},
				0.1)
			.to($('.social__loader'), 0.01, {className: '+=is-active'})
			.to($('.social__loader_content'), 0.01, {zIndex: 2})
			.fromTo($('.social__loader_content .icon'), 0.4, {opacity: 0}, {opacity: 1}, '-=0.1')
			.to($('.social__loader_content .icon'), 0.01, {className: '+=active'}, '-=0.05')
		// .to($('.social__loader_content .icon'), .1, {className:"+=active"})
			.call(() => {
				window.location = href;
			}, null, null, '+=1')
			.pause();

		animatedSocialLink.play();
	});

	var animatedNav = new TimelineMax({pause: true});

	animatedNav
		.to($('.header__toggle'), 0.001, {className: '+=on'})
		.fromTo($('.nav'), 1,
			{x: '100%'},
			{x: '0%'})
		.fromTo($('.nav__bg'), 0.8,
			{x: '100%',
				opacity: 0},
			{x: '10%',
				opacity: 1}, 0.2)
		.staggerFromTo($($('.nav__list').children().get().sort(() => {
			return Math.random() - 0.5;
		})),
		0.25,
		{x: 50,
			opacity: 0},
		{x: 0,
			opacity: 1},
		0.07, 0.4)
		.fromTo($('.nav__head'), 0.25,
			{x: 50,
				opacity: 0},
			{x: 0,
				opacity: 1}, 0.5)
		.staggerFromTo($($('.nav .menu__contact').children().get().sort(() => {
			return Math.random() - 0.5;
		})),
		0.25,
		{x: 50,
			opacity: 0},
		{x: 0,
			opacity: 1},
		0.07, 0.5)
		.timeScale(1.4)
		.pause();

	$(document).on('click', '.header__toggle', () => {
		if ($('.menu__item-5.active').length) {
			animatedMenuContact.reverse();
		}
		if (animatedNav.isActive()) {
			return false;
		}
		closeMainMenu();
		if ($('.header__toggle').hasClass('on')) {
			animatedNav.reverse();
		} else {
			animatedNav.play();
		}
	});

	$(document).on('click', '.header__show', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('.header__list').toggleClass('active');
	});

	let animatedServices = [];

	$('.services__item').each(function () {
		let $this = $(this);

		let img = $this.find('.services__img img');

		let price = $this.find('.services__price');

		let btn = $this.find('.services__btn');

		let item = new TimelineMax({pause: true})
			.to(img, 0.3,
				{x: '100%'})
			.to($this, 0.001, {className: '+=active'})
			.to(price, 0.3,
				{y: '0%'},
				0.3)
			.to(btn, 0.2,
				{opacity: 1},
				0.55)
			.pause();

		animatedServices.push(item);
	});

	$('.services__item').hover(
		function () {
			let index = $(this).index();

			animatedServices[index]
				.timeScale(1)
				.play();
		},
		function () {
			let index = $(this).index();

			animatedServices[index]
				.timeScale(2)
				.reverse();
		}
	);

	$(document).on('click', '.inner__nav_link', function (e) {
		e.preventDefault();
		let $this = $(this);

		let parent = $this.parent();

		let index = parent.index();

		let items = $this.parents('.inner').find('.inner__tabs_item');

		$this.addClass('active');
		$this.parent('li').addClass('active');
		parent.siblings().find('.inner__nav_link').removeClass('active');
		parent.siblings().find('.inner__nav_link').parent('li').removeClass('active');
		items.removeClass('active').eq(index).addClass('active');
	});

	const $btnMobileTabs = $('.js-tab-link-mobile');

	$btnMobileTabs.on('click', function () {
		let index = $(this).data('index');

		let $item = $($('.inner__nav li')[index]);

		let $link = $item.find('.inner__nav_link');

		$btnMobileTabs.removeClass('active');
		$(this).addClass('active');

		$link.click();
	});

	// swiper remove mobile
	const swiperRemoveBreakpiont = function (select, option) {
		if (typeof Swiper === 'undefined') {
			return;
		}

		const breakpoint = window.matchMedia('(max-width:575px)');

		let mySwiper;

		const enableSwiper = function () {
			mySwiper = new Swiper(select, option);
		};

		const breakpointChecker = function () {
			if (breakpoint.matches === true) {
				if (mySwiper !== undefined) {
					mySwiper.destroy(true, false);
					$('.swiper-container, .swiper-wrapper').removeAttr('style');
					$('.cases__item, .swiper-slide').css({width: ''});
				}
			} else if (breakpoint.matches === false) {
				return enableSwiper();
			}
		};

		$(window).on('load resize', debounce(breakpointChecker, 200));
	};

	// cases__slider
	const casesSlideOption = {
		spaceBetween: 0,
		slideToClickedSlide: true,
		// loop: true,
		pagination: {
			el: '.cases__slider .swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.cases__slider .swiper-button-next',
			prevEl: '.cases__slider .swiper-button-prev',
		},
		breakpoints: {
			4992: {
				slidesPerView: 3,
			},
			991: {
				slidesPerView: 2,
				slidesPerColumn: 40,
				slidesPerColumnFill: 'row',
			},
			600: {
				slidesPerView: 1,
				slidesPerColumn: 40,
				slidesPerColumnFill: 'row',
			},
		},
	};

	swiperRemoveBreakpiont('.cases__slider.swiper-container', casesSlideOption);

	// cases__all
	const casesAllOption = {
		spaceBetween: 0,
		slidesPerView: 'auto',
		mousewheel: true,
		// loop: true,
		// pagination: {
		//     el: '.cases__all .swiper-pagination',
		//     type: 'bullets',
		//     clickable: true
		// },
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		scrollbar: {
			el: '.cases__all .swiper-scrollbar',
			draggable: true,
		},
		navigation: {
			nextEl: '.cases__all .swiper-button-next',
			prevEl: '.cases__all .swiper-button-prev',
		},
		breakpoints: {
			991: {
				slidesPerView: 2,
			},
			600: {
				slidesPerView: 1,
			},
		},
	};

	swiperRemoveBreakpiont('.cases__all .swiper-container', casesAllOption);

	if ($('.js-popup').length > 0) {
		$('.js-popup').magnificPopup({
			type: 'inline',
			fixedContentPos: false,
			fixedBgPos: true,
			overflowY: 'auto',
			closeBtnInside: true,
			preloader: false,
			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in',
		});
	}
});

let animatedMain = new TimelineMax({pause: true});

function loadMain() {
	$('.home__desc').html($('.home__desc').text().split('').map((item) => {
		return `<span>${item}</span>`;
	}).join(''));
	animatedMain
		.fromTo($('.header__logo'), 0.3, {y: -100}, {y: 0})
		.staggerFromTo($('.header__right').children(), 0.5,
			{y: -80,
				opacity: 0},
			{y: 0,
				opacity: 1},
			0.1, 0.2)
		.staggerFromTo($($('.social__list .social__link').get().reverse()), 0.3,
			{x: 100,
				opacity: 0},
			{x: 0,
				opacity: 1},
			0.1, 0.3)
		.staggerFromTo($('.menu__item'), 0.3,
			{y: '-100%',
				opacity: 0},
			{y: '0%',
				opacity: 1},
			0.1, 0.5)

		.fromTo($('.inner, .js-open-load'), 0.8,
			{y: '35%',
				scaleX: 1,
				scaleY: 1,
				opacity: 0},
			{y: '0%',
				scaleX: 1,
				scaleY: 1,
				opacity: 1},
			0.6
		)

		.fromTo($('.case__wr'), 0.8,
			{y: '35%',
				scaleX: 1,
				scaleY: 1,
				opacity: 0},
			{y: '0%',
				scaleX: 1,
				scaleY: 1,
				opacity: 1},
			0.6
		)
		.fromTo($('.case__info'), 0.8,
			{x: '35%',
				scaleX: 1,
				scaleY: 1,
				opacity: 0},
			{x: '0%',
				scaleX: 1,
				scaleY: 1,
				opacity: 1},
			0.6
		)

		.fromTo($('.home__content'), 0.5,
			{scaleX: 0.7,
				scaleY: 0.7,
				opacity: 0},
			{scaleX: 1,
				scaleY: 1,
				opacity: 1},
			0.6
		)
		.staggerFromTo($($('.home__best > *').get().reverse()), 0.4,
			{x: 90,
				opacity: 0},
			{x: 0,
				opacity: 1},
			0.15, '-=.2')
		.staggerFromTo($($('.home__desc > span').get().reverse()), 0.3,
			{rotationY: 90},
			{rotationY: 0},
			0.15, '-=.5')
		.fromTo($('.footer'), 0.3, {opacity: 0}, {opacity: 1}, '-=0.3')
		.call(() => {
			$('.home').addClass('active');
		}, null, null, '-=.2')
		.pause();
}
loadMain();
// animatedMain.progress(1);

window.onload = function () {
	$('.preloader')
		.fadeOut('slow', () => {
			// $(this).remove();
			animatedMain.play();
		});

	const isMobile = $(window).width() < 767;

	if (isMobile) {
		$('video source').remove();
	}
};
