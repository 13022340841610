import $ from 'jquery';
import {TimelineLite} from 'gsap';
import {debounce} from 'lodash';
import widthAtResize from '../utils/width-at-resize';

const $aboutItems = $('.about-us-list__item');
const $aboutLink = $('.js-list-title');
const ease = Circ.easeInOut;
const timeEase = 0.7;

let index = null;

const togglesBoxBg = (url = null, text = null, newIndex = null) => {
	const toggleBoxBg = new TimelineLite();
	const toggleBoxText = new TimelineLite();
	const lineText = new TimelineLite();
	const aboutAnimBox = '.about-us-animate';
	const aboutItemText = '.about-us-list__text';
	const $aboutBoxBg = $('.about-us-animate__bg');
	const $aboutTextBox = $('.about-us-text');
	const isHideBoxBg = $(aboutAnimBox).width() > 0;
	const isIndex = newIndex !== index || null;
	const isMobile = $(window).width() < 767;

	const addClassActive = (activeClass = 'active') => {
		$aboutItems.removeClass(activeClass);
		if (newIndex !== null && isIndex) {
			$($aboutItems[newIndex]).addClass(activeClass);
		}
	};

	const openBg = () => {
		$aboutBoxBg.css({backgroundImage: `url(${url})`});
		$aboutTextBox.text(text);
		index = newIndex;

		toggleBoxBg
			.to(aboutAnimBox, timeEase, {
				width: '100%',
				ease,
			});

		toggleBoxText
			.to($aboutTextBox, timeEase, {
				opacity: 1,
				transform: 'translateY(0)',
				ease,
			});

		if (isMobile) {
			$($aboutItems[newIndex]).find(aboutItemText).slideDown();
		} else {
			lineText.to($($aboutItems[newIndex]).find(aboutItemText), timeEase, {
				opacity: 1,
				transform: 'translateY(0)',
				ease,
			});
		}
	};

	const closeBg = () => {
		index = null;

		toggleBoxBg
			.to(aboutAnimBox, timeEase, {
				width: '0%',
				ease,
			});

		toggleBoxText
			.to($aboutTextBox, timeEase, {
				opacity: 0,
				transform: 'translateY(50%)',
				ease,
			});

		if (isMobile) {
			$(aboutItemText).slideUp();
		} else {
			lineText.to($(aboutItemText), timeEase, {
				opacity: 0,
				transform: 'translateY(-50px)',
				ease,
			});
		}

		if (isIndex) {
			toggleBoxBg.eventCallback('onComplete', togglesBoxBg, [url, text, newIndex]);
		}
	};

	addClassActive();

	if (!isHideBoxBg && isIndex) {
		openBg();
	} else {
		closeBg();
	}
};

const aboutOnClickList = (event) => {
	const self = event.currentTarget;
	const url = $(self).data('image-url');
	const text = $(self).data('text');
	const newIndex = $(self).data('index');

	togglesBoxBg(url, text, newIndex);
};

$aboutLink.on('click', debounce(aboutOnClickList, 200));

const addCssElement = (element, option = null) => {
	$(element).css({...option});
};

widthAtResize('.about-us', (width) => {
	const backgroundPositionY = $('.about-us').height() / 2.4;

	addCssElement('.about-us-animate__bg', {width, backgroundPositionY}); // eslint-disable-line
	addCssElement('.about-us', {backgroundPositionY});
});

