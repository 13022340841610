import $ from 'jquery';

const clickOutside = (elem, fn = () => {}) => {
	const notElem = (event) => {
		return (
			!elem.is(event.target) &&
			elem.has(event.target).length === 0
		);
	};

	$(document).mouseup((event) => {
		event.preventDefault();

		if (notElem(event)) {
			fn();
		}
	});
};

export default clickOutside;
