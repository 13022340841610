import Swiper from 'swiper';

const galleryTop = new Swiper('.gallery-top', {
	effect: 'fade',
	speed: 1000,
	slidesPerView: 1,
	fadeEffect: {crossFade: true},
});

const galleryThumbs = new Swiper('.gallery-thumbs', { // eslint-disable-line
	slidesPerView: 5,
	centeredSlides: true,
	spaceBetween: 30,
	speed: 1000,
	slideToClickedSlide: true,
	keyboard: {
		enabled: true,
		onlyInViewport: false,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	thumbs: {
		swiper: galleryTop,
	},
	breakpoints: {
		1024: {
			slidesPerView: 5,
		},
		991: {
			slidesPerView: 3,
		},
		767: {
			slidesPerView: 1,
		},
	},
});

