import $clamp from 'clamp-js';
import {debounce} from 'lodash';

const $text = $('.detail-box__text');

const clampText = () => {
	$text.each((index, element) => {
		$clamp(element, {clamp: 'auto'});
	});
};

$(window).on('load resize', debounce(clampText, 200));

