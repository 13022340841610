import {debounce, delay} from 'lodash';
import clickOutside from '../utils/click-outside';

const $tabsBox = $('.inner__tabs');
const $wheWeList = $('.why-we-list__item');
const $wheWeListIcon = $('.why-we-list__icon');
const $rowList = $('.js-effect-row .col-md');

let left = null;

let right = null;

let indexItem = null;

const hideAnimColumn = () => {
	$rowList.removeClass('open-left open-right');
	$wheWeList.removeClass('is-open');
};

const hideAnimColumnAll = () => {
	hideAnimColumn();
	left = null;
	right = null;
	indexItem = null;
};

const showAnimColumn = () => {
	$($rowList[left]).addClass('open-left');
	$($rowList[right]).addClass('open-right');
	$($wheWeList[indexItem]).addClass('is-open');
};

$wheWeListIcon.on('click', debounce((event) => {
	const self = event.currentTarget;
	const newLeft = $(self).data('left');
	const newRight = $(self).data('right');
	const isNewLeftAndRight = left !== newLeft && right !== newRight;

	if (isNewLeftAndRight) {
		left = newLeft;
		right = newRight;
		indexItem = $(self).parent('.why-we-list__item').index();
	} else {
		hideAnimColumnAll();
	}

	hideAnimColumn();
	delay(showAnimColumn, 300);
}, 200));

clickOutside($tabsBox, hideAnimColumnAll);
