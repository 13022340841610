import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import Swiper from 'swiper';
import 'gsap';
import 'magnific-popup';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min';

svg4everybody();

window.$ = $;
window.jQuery = $;
window.Swiper = Swiper;

require('ninelines-ua-parser');
